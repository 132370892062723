module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WXY3YFNQ7Z"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Video Hub App","short_name":"VHA","start_url":"/","background_color":"#80cf0f","theme_color":"#80cf0f","display":"minimal-ui","icon":"static/vha-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"752cc5479cb4c27e7655eddf509e8c02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Code\\VHA\\website/src/intl","languages":["ar","bn","zh","cs","nl","en","fr","de","ha","iw","hi","it","ja","ko","ms","pl","pt","ru","es","tr","uk","ur","vi"],"defaultLanguage":"en","redirect":true,"redirectComponent":"C:\\Code\\VHA\\website\\src\\components\\redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
